@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app{
 /*background-color: #000;*/
 background: url('components/assets/images/bg1.jpg') no-repeat center center fixed;
  background-size: cover;
}
.content {
  height: 100%;
  width: 100%;
  font-family:-apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.dashboard {
  display: flex;
  position: relative;
  max-width: 100%;
  background-color: #fff;
  padding:10px;
}

/* Scrollbar */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #585965 #e0e0e0;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #4a5975;
}

*::-webkit-scrollbar-thumb:hover {
  background: #272f56;
}

*::-webkit-scrollbar-thumb {
  background-color: #30345d;
  border-radius: 3px;
  /* border: 1px solid #4c4d56; */
}
