
.auth{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fafafa;
}

.box {
  border: 2px solid #495057;
  background-color: #fff;
  margin: auto;
  width: 450px;
  margin-top: 100px;
  border-radius: 3px;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}

.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
